import { isDefined } from "@clipboard-health/util-ts";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import { convertToGeoLocation } from "@src/appV2/Location";
import { OpenGeoLocationBottomSheet } from "@src/appV2/redesign/OpenGeoLocation/OpenGeoLocationBottomSheet";
import { OpenGeoLocationProvider } from "@src/appV2/redesign/OpenGeoLocation/useOpenGeoLocation";
import { useWorkerLocations } from "@src/appV2/redesign/ShiftDiscovery/MapView/useWorkerLocations";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { WorkplaceNoteIdentifier } from "@src/appV2/redesign/Workplace/constants";
import { useDrivingDistanceToWorkplace } from "@src/appV2/redesign/Workplace/useDrivingDistanceToWorkplace";
import { useWorkplaceNotesParsed } from "@src/appV2/redesign/Workplace/useWorkplaceNotesParsed";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { parseISO, secondsToMinutes, subMinutes } from "date-fns";

import { ParkingCardContent } from "./ParkingCardContent";

interface ShiftDetailsParkingCardProps {
  shift: Pick<Shift, "start" | "facility">;
}

const EXTRA_BUFFER_MINUTES = 5;

export function ShiftDetailsParkingCard(props: ShiftDetailsParkingCardProps) {
  const { shift } = props;

  const facilityAddress = shift?.facility.fullAddress?.formatted ?? "";
  const facilityGeoLocation = isDefined(shift.facility.geoLocation?.coordinates)
    ? convertToGeoLocation(shift.facility.geoLocation.coordinates)
    : undefined;

  const { workerLocation } = useWorkerLocations();

  const { data: notes = {}, isSuccess: isWorkplaceNotesSuccess } = useWorkplaceNotesParsed(
    shift.facility.userId ?? "",
    {
      enabled: isDefined(shift.facility.userId) && shift.facility.userId.length > 0,
    }
  );

  const parkingInstructions = isWorkplaceNotesSuccess
    ? notes[WorkplaceNoteIdentifier.PARKING_INSTRUCTIONS]?.note ?? undefined
    : undefined;

  const workerGeolocation = isDefined(workerLocation)
    ? {
        latitude: workerLocation.lat,
        longitude: workerLocation.lng,
      }
    : undefined;

  const { data: distanceData } = useDrivingDistanceToWorkplace({
    workplaceGeoLocation: facilityGeoLocation,
    workerGeoLocation: workerGeolocation,
    enabled: isDefined(facilityGeoLocation) && isDefined(workerLocation),
  });

  const travelTimeInMinutes = isDefined(distanceData)
    ? Math.ceil(secondsToMinutes(distanceData.duration.value))
    : undefined;

  const shiftStartDate = parseISO(shift.start);
  const estimatedDepartureTime =
    isDefined(travelTimeInMinutes) && travelTimeInMinutes > 0
      ? formatTime(
          subMinutes(shiftStartDate, travelTimeInMinutes + EXTRA_BUFFER_MINUTES),
          shift.facility.tmz
        )
      : undefined;
  const estimatedTravelTime = isDefined(estimatedDepartureTime)
    ? `${travelTimeInMinutes} min drive`
    : undefined;

  const location: Location = {
    lat: facilityGeoLocation?.latitude ?? 0,
    lng: facilityGeoLocation?.longitude ?? 0,
  };

  return (
    <OpenGeoLocationProvider
      location={location}
      name={shift.facility.name}
      formattedAddress={facilityAddress}
    >
      <ParkingCardContent
        estimatedDepartureTime={estimatedDepartureTime}
        estimatedTravelTime={estimatedTravelTime}
        parkingInstructions={parkingInstructions}
      />
      <OpenGeoLocationBottomSheet />
    </OpenGeoLocationProvider>
  );
}
