import { Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { useOpenGeoLocationContext } from "@src/appV2/redesign/OpenGeoLocation/useOpenGeoLocation";

import { ShiftDetailsInnerCard } from "./InnerCard";

interface ParkingCardContentProps {
  estimatedDepartureTime: string | undefined;
  estimatedTravelTime: string | undefined;
  parkingInstructions: string | undefined;
}

export function ParkingCardContent(props: ParkingCardContentProps) {
  const { estimatedDepartureTime, estimatedTravelTime, parkingInstructions } = props;
  const { href, onClick: onGetDirections } = useOpenGeoLocationContext();

  return (
    <ShiftDetailsInnerCard
      footer={
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          onClick={() => {
            if (onGetDirections) {
              onGetDirections();
            } else {
              window.open(href, "_blank");
            }
          }}
        >
          Get directions in maps
        </Button>
      }
    >
      <CardContent>
        <Stack spacing={4} alignItems="center" sx={{ paddingX: 4, paddingY: 4 }}>
          <Illustration type="driving" />
          {isDefined(estimatedDepartureTime) && (
            <Text semibold variant="h5" sx={{ textAlign: "center" }}>
              Leave home at {estimatedDepartureTime}
              {isDefined(estimatedTravelTime) && (
                <Text semibold variant="h5" sx={{ textAlign: "center" }}>
                  ({estimatedTravelTime})
                </Text>
              )}
            </Text>
          )}
          {isDefined(parkingInstructions) && (
            <Text
              variant="body2"
              sx={{ color: (theme) => theme.palette.text.secondary, textAlign: "center" }}
            >
              Parking instructions: <i>{parkingInstructions}</i>
            </Text>
          )}
        </Stack>
      </CardContent>
    </ShiftDetailsInnerCard>
  );
}
