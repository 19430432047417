import { HorizontalScrollView, ListItem } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { WorkplaceNoteIdentifier } from "@src/appV2/redesign/Workplace/constants";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { parseISO } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { CancellationPolicyCard } from "./CancellationPolicyCard";
import { MandatoryBreakCard } from "./MandatoryBreakCard";
import { ShiftDetailsParkingCard } from "./ParkingCard";

interface HorizontalCardWrapperListItemProps {
  shift: Shift;
  onCancelShift: () => void;
}

export function HorizontalCardWrapperListItem(props: HorizontalCardWrapperListItemProps) {
  const { shift, onCancelShift } = props;

  const history = useHistory();

  const { data: workplaceNotesData } = useFacilityNotes(shift?.facility.userId ?? "", {
    enabled: isDefined(shift?.facility.userId),
  });

  const cancellationPolicyNote = workplaceNotesData?.find(
    (note) => note.identifier === WorkplaceNoteIdentifier.CANCEL_INSTRUCTIONS
  );

  const lastUpdatedAt = cancellationPolicyNote?.updatedAt ?? undefined;

  const handleViewWorkplacePolicies = () => {
    history.push(
      generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
        facilityId: shift.facility.userId,
      })
    );
  };

  return (
    <ListItem divider>
      <HorizontalScrollView stretch sx={{ px: 5, height: "max-content" }}>
        <ShiftDetailsParkingCard shift={shift} />
        <MandatoryBreakCard shift={shift} onViewWorkplacePolicies={handleViewWorkplacePolicies} />
        <CancellationPolicyCard
          workplaceLateCancellationPeriod={shift.facility.lateCancellation?.period}
          workplaceLateCancellationFeeHours={shift.facility.lateCancellation?.feeHours}
          workplaceSentHomeChargeHours={shift.facility.sentHomeChargeHours}
          lastUpdatedAt={isDefined(lastUpdatedAt) ? parseISO(lastUpdatedAt) : undefined}
          onViewWorkplacePolicies={handleViewWorkplacePolicies}
          onCancelShift={onCancelShift}
        />
      </HorizontalScrollView>
    </ListItem>
  );
}
