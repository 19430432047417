import { CardWithIntent, type CardWithIntentProps } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import { type IntentVariant } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import type { ReactNode, Ref } from "react";
import { forwardRef } from "react";

export type ShiftDetailsInnerCardIntent = IntentVariant | "priority";

interface ShiftDetailsInnerCardProps extends Omit<CardWithIntentProps, "intent"> {
  intent?: ShiftDetailsInnerCardIntent;
  footer?: ReactNode;
}

function BaseShiftDetailsInnerCard(props: ShiftDetailsInnerCardProps, ref: Ref<HTMLDivElement>) {
  const { sx, intent = "neutral", footer, children, ...restProps } = props;

  return (
    <CardWithIntent
      {...restProps}
      ref={ref}
      intent={intent === "priority" ? undefined : intent}
      sx={mergeSxProps(
        {
          minHeight: "20rem",
          maxHeight: "100%",
          width: 315,
          borderRadius: 4,
          boxShadow: "none",
          cursor: "pointer",
          flexShrink: 0,
          mx: 1,
          "&:first-of-type": { ml: 0 },
          "&:last-of-type": { mr: 0 },
        },
        sx
      )}
    >
      <Stack sx={{ height: "100%" }}>
        <Box sx={{ flex: 1 }}>{children}</Box>
        {isDefined(footer) && (
          <Box sx={{ marginTop: "auto", paddingX: 6, paddingBottom: 6, paddingTop: 2 }}>
            {footer}
          </Box>
        )}
      </Stack>
    </CardWithIntent>
  );
}

export const ShiftDetailsInnerCard = forwardRef(BaseShiftDetailsInnerCard);
