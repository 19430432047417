import { Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { MANDATORY_BREAK_DURATION_IN_MINUTES } from "@src/appV2/redesign/Shift/constants";
import { doesShiftRequireBreak } from "@src/appV2/redesign/utils/doesShiftRequireBreak";
import { type Shift } from "@src/appV2/Shifts/Shift/types";

import { ShiftDetailsInnerCard } from "./InnerCard";

interface MandatoryBreakCardProps {
  shift: Pick<Shift, "start" | "end" | "facility">;
  onViewWorkplacePolicies: () => void;
}

export function MandatoryBreakCard(props: MandatoryBreakCardProps) {
  const { shift, onViewWorkplacePolicies } = props;

  if (!doesShiftRequireBreak(shift)) {
    return null;
  }

  return (
    <ShiftDetailsInnerCard
      footer={
        <Button fullWidth variant="outlined" size="medium" onClick={onViewWorkplacePolicies}>
          View facility policies
        </Button>
      }
    >
      <CardContent>
        <Stack spacing={4} alignItems="center" sx={{ paddingX: 4, paddingY: 4 }}>
          <Illustration type="break" />
          <Text semibold variant="h5" sx={{ textAlign: "center" }}>
            A {MANDATORY_BREAK_DURATION_IN_MINUTES}-minute unpaid break is required
          </Text>
          <Text
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary, textAlign: "center" }}
          >
            Break time will be automatically applied to your timesheet after clock out.
          </Text>
        </Stack>
      </CardContent>
    </ShiftDetailsInnerCard>
  );
}
