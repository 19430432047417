import { List } from "@clipboard-health/ui-components";
import { Box, Skeleton } from "@mui/material";

import { ShiftDetailsInnerCard } from "./InnerCard";

export function ShiftDetailsPageSkeleton() {
  return (
    <List sx={{ overflowY: "hidden", paddingY: 4 }}>
      <Box sx={{ px: 2, py: 4 }}>
        <Skeleton variant="text" width="40%" height={24} />
        <Skeleton variant="text" width="60%" height={24} sx={{ mt: 1 }} />
      </Box>

      <Box sx={{ p: 2, display: "flex", gap: 2, overflowX: "hidden" }}>
        <ShiftDetailsInnerCard>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </ShiftDetailsInnerCard>
        <ShiftDetailsInnerCard>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </ShiftDetailsInnerCard>
        <ShiftDetailsInnerCard>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </ShiftDetailsInnerCard>
      </Box>

      <Box sx={{ px: 2, py: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={80} />
      </Box>

      <Box sx={{ px: 2, py: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Box>
    </List>
  );
}
