import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { HourlyPayLabel } from "@src/appV2/redesign/Pay/HourlyPayLabel";
import { TotalPayLabel } from "@src/appV2/redesign/Pay/TotalPayLabel";
import { InstantPayIndicator } from "@src/appV2/redesign/Shift/BottomSheet/OnCallShift/InstantPayIndicator";
import { getFormattedShiftPayBreakdown } from "@src/appV2/redesign/Shift/getFormattedShiftPayBreakdown";
import type { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import type { WorkerShift } from "@src/appV2/Shifts/Shift/types";

import { Button } from "../../../components/Button";
import { StickyFooter } from "../../../components/StickyFooter";
import { ClockInOptionsBottomSheet } from "./ClockInOptionsBottomSheet";

interface ShiftDetailsFooterProps {
  shift: WorkerShift;
  shiftState: ShiftStateData;
  breakPolicyNoteId: string;
  requiresBreakPolicyAcknowledgement: boolean;
  onCancelShift: () => void;
}

function ShiftDetailsFooter(props: ShiftDetailsFooterProps) {
  const {
    shift,
    shiftState,
    breakPolicyNoteId,
    requiresBreakPolicyAcknowledgement,
    onCancelShift,
  } = props;

  const clockInBottomSheetModalState = useModalState();

  const payBreakdown = getFormattedShiftPayBreakdown(
    shift.offer?.pay.value,
    shift.finalPay,
    shift.originalAmount
  );

  return (
    <StickyFooter>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingX: 2, marginBottom: 5 }}
      >
        <HourlyPayLabel size="large" hourlyPay={payBreakdown?.hourlyPay} />
        <Stack direction="row" spacing={2} alignItems="center">
          <TotalPayLabel totalPay={payBreakdown?.totalPay} />
          {isDefined(shift) && shift.isInstantPay && <InstantPayIndicator />}
        </Stack>
      </Stack>

      <Button
        fullWidth
        variant="contained"
        size="large"
        endIconType="chevron-down"
        onClick={() => {
          clockInBottomSheetModalState.openModal();
        }}
      >
        Clock-in options
      </Button>

      <ClockInOptionsBottomSheet
        modalState={clockInBottomSheetModalState}
        shift={shift}
        shiftState={shiftState}
        breakPolicyNoteId={breakPolicyNoteId}
        requiresBreakPolicyAcknowledgement={requiresBreakPolicyAcknowledgement}
        onCancelShift={() => {
          onCancelShift();
        }}
      />
    </StickyFooter>
  );
}

export { ShiftDetailsFooter };
