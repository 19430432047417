import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { getGradientByTimeSlot } from "@src/appV2/redesign/Shift/getGradientByTimeSlot";
import { type ShiftPeriod, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../../components/IconButton";

interface ShiftDetailsHeaderWrapperProps {
  onBackClick?: () => void;
  shiftName?: string;
  title: string;
}

export function ShiftDetailsHeaderWrapper(props: ShiftDetailsHeaderWrapperProps) {
  const { onBackClick, shiftName, title } = props;
  const history = useHistory();

  function handleBackClick() {
    if (onBackClick) {
      onBackClick();
    } else {
      history.goBack();
    }
  }

  return (
    <PageHeaderWrapper sx={{ paddingTop: 0 }}>
      <Stack
        sx={(theme) => ({
          paddingBottom: 7,
          paddingTop: 5,
          paddingX: 6,
          transition: theme.transitions.create(["background"]),
          background: isDefined(shiftName)
            ? getGradientByTimeSlot({
                timeSlot: shiftPeriodToTimeSlot[shiftName as ShiftPeriod],
                theme,
              })
            : undefined,
        })}
      >
        <Stack spacing={6}>
          <IconButton
            iconType="arrow-left"
            variant="outlined"
            onClick={() => {
              handleBackClick();
            }}
          />

          <Title component="h3" variant="h3">
            {title}
          </Title>
        </Stack>
      </Stack>
    </PageHeaderWrapper>
  );
}
